import React from 'react';
import styles from './index.module.scss';
import Pagination from '../Pagination';
import LoadingTable from '../LoadingTable';

interface RowTB {
  title: string;
  dataIndex: string;
  key: string;
  width?: string;
  render?: (index: number, record: any) => JSX.Element;
  pos?: string;
  id?: any;
}

export interface ITable {
  columns: RowTB[];
  data: any[];
  loading?: boolean;
  currentPage: number;
  totalPage: number;
  handlePagination?: any;
  pageSize?: number;
  handleShowInfoMD5?: any;
}
const CustomTable = (props: ITable): JSX.Element => {
  const {
    columns,
    data,
    currentPage,
    totalPage,
    pageSize,
    handlePagination,
    handleShowInfoMD5,
    loading,
  } = props;
  console.log('loading', loading);
  return (
    <div className={styles.tableContainer}>
      {loading && (
        <div className={styles.loading}>
          <LoadingTable />
        </div>
      )}
      <table className={styles.transTable}>
        <colgroup>
          {columns.map((item, index) => (
            <col span={1} key={index} />
          ))}
        </colgroup>
        <thead>
          <tr className={styles.tableHeader}>
            {columns.map((item) => (
              <th key={item.key}>
                <div className='font-bold-14'>{item.title} </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: number) => {
            return (
              <tr className={styles.tableRow} key={index}>
                {columns.map((el) => {
                  if (el.key === 'stt') {
                    return (
                      <td key={el.key} className={styles.sttTable}>
                        {index +
                          1 +
                          currentPage * Number(pageSize) -
                          Number(pageSize)}
                      </td>
                    );
                  }

                  if (el.render) {
                    return <td key={el.key}>{el.render(index, item)}</td>;
                  }

                  return (
                    <td
                      key={el.key}
                      className={el.key === 'md5' ? styles.noteMD5 : ''}
                      onClick={() => {
                        if (el.key === 'md5') {
                          handleShowInfoMD5(item?.md5_value);
                        }
                      }}>
                      {item[el.dataIndex]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data?.length === 0 || !data ? (
        <div className={styles.notData}>
          <img
            width={200}
            height={200}
            src='map_not-data.png'
            alt='location-not-data'
          />
        </div>
      ) : (
        <Pagination
          page={currentPage}
          handlePagination={handlePagination}
          totalPages={totalPage}
        />
      )}
    </div>
  );
};

export default CustomTable;
