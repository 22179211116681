import React, { useState } from 'react';
import style from './app.module.scss';
import ReleaseHistoty from './layouts/ReleaseHistory';
import StatisticLocameet from './layouts/StatisticLocameet';

const TYPE = {
  TAB1: 'TAB1',
  TAB2: 'TAB2',
};

const listTabs = [
  { id: 1, name: 'Thống kê Locameet', tab: TYPE.TAB1 },
  { id: 2, name: 'Lịch sử phát hành', tab: TYPE.TAB2 },
];

const tabLBC = (type: string) => {
  switch (type) {
    case TYPE.TAB1:
      return <StatisticLocameet />;
    case TYPE.TAB2:
      return <ReleaseHistoty />;
    default:
      return;
  }
};

function App() {
  const [tab, setTab] = useState(TYPE.TAB1);

  return (
    <div className={style.container}>
      <img src='LocaMeet.png' alt='locameet' className={style.logo} />
      <div className={style.title}>Tra cứu lịch sử phát hành LocaBonus</div>

      {listTabs.map((item) => (
        <div
          className={style.wrapperTab}
          onClick={() => setTab(item.tab)}
          key={item.id}>
          <div className={tab === item.tab ? style.tab_active : style.tab}>
            {item.name}
          </div>
        </div>
      ))}

      <div className={style.scrollTable}>{tabLBC(tab)}</div>
    </div>
  );
}

export default App;
