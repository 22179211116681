import React from 'react';
import style from './box.module.scss';
import { numberWithDots } from '../../utils/common';
interface BoxProps {
  name: string;
  total?: number;
  isDate?: boolean;
  date?: string;
}

function Box(props: BoxProps) {
  const { name, total, isDate, date } = props;

  return (
    <div className={style.wrapperBox}>
      <div className={style.nameBox}>{name}</div>
      {isDate ? (
        <div className={style.totalBox}>{date}</div>
      ) : (
        <div className={style.totalBox}>
          {total ? numberWithDots(total) : 0}
        </div>
      )}
    </div>
  );
}

export default Box;
