import React from 'react';
import Box from '../../components/Box';
import { timeDateFormat } from '../../utils/common';
import style from './listBox.module.scss';

function ListBoxStatisticLocameet({ data }: any) {
  return (
    <div className={style.rowBox}>
      <Box
        name='Lượng Điểm LocaBonus trung bình phát hành tuần trước'
        total={data?.lastWeekAvgReleaseToken}
      />
      <Box
        name='Lượng Điểm LocaBonus trung bình phát hành tuần này'
        total={data?.currentWeekAvgReleaseToken}
      />
      <Box
        name='Lượng Điểm LocaBonus phát hành ra mỗi giờ'
        total={data?.currentHourReleaseToken}
      />
      <Box name='Số hoạt động giờ trước' total={data?.lastHourTotalAction} />
      <Box name='Số hoạt động trong ngày' total={data?.todayTotalAction} />
      <Box
        name='Thời gian phát hành lần cuối'
        date={timeDateFormat(data?.lastestReleaseTokenTime)}
        isDate={true}
      />
      <Box name='Tổng quỹ DAO' total={data?.totalDaoAmount} />
    </div>
  );
}

export default ListBoxStatisticLocameet;
