import React from 'react';
import Button from '../Button';
import RCDatePicker from '../DatePicker';
import style from './input-date.module.scss';

interface InputDateFromToProps {
  setDate: any;
  date: any;
  handleFilterDateTran: () => void;
  isMonth?: boolean;
}

function InputDateFromTo(props: InputDateFromToProps) {
  const { date, setDate, handleFilterDateTran, isMonth } = props;
  const { dateTo, dateFrom, errorDateTo, errorDateFrom } = date;

  const textDate = isMonth ? 'tháng' : 'ngày';

  const onChangeDateFrom = (val: string) => {
    if (Date.parse(dateTo) < Date.parse(val)) {
      setDate({
        ...date,
        dateFrom: val,
        errorDateFrom: `Thời gian từ ${textDate} phải nhỏ hơn đến ${textDate}`,
      });
    } else {
      setDate({ ...date, dateFrom: val, errorDateFrom: '', errorDateTo: '' });
    }
  };

  const onChangeDateTo = (val: string) => {
    if (Date.parse(dateFrom) > Date.parse(val)) {
      setDate({
        ...date,
        dateTo: val,
        errorDateTo: `Thời gian đến ${textDate} phải lớn hơn từ ${textDate}`,
      });
    } else {
      setDate({ ...date, dateTo: val, errorDateTo: '', errorDateFrom: '' });
    }
  };

  return (
    <div className={style.filterDateTran}>
      <div>
        <RCDatePicker
          label={`Từ ${textDate}`}
          placeholder={`Từ ${textDate}`}
          value={dateFrom}
          onChange={onChangeDateFrom}
          isMonth={isMonth}
        />
        <div className='text-err'>{errorDateFrom}</div>
      </div>
      <div>
        <RCDatePicker
          label={`Đến ${textDate}`}
          placeholder={`Đến ${textDate}`}
          value={dateTo}
          onChange={onChangeDateTo}
          isMonth={isMonth}
        />
        <div className='text-err'>{errorDateTo}</div>
      </div>
      <div className={style.buttonFilterDate}>
        <Button
          isDisabled={!errorDateTo && !errorDateFrom ? false : true}
          text='Tìm kiếm'
          handleSubmit={handleFilterDateTran}
        />
      </div>
    </div>
  );
}

export default InputDateFromTo;
