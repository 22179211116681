import axios from 'axios';
import dayjs from 'dayjs';
const url: any = process.env.REACT_APP_PUBLIC_URL;

interface InputProps {
  limit: number;
  page: number;
  searchMeet: string;
  searchReciever?: string;
  dateFrom: string;
  dateTo: string;
}

const getListLocameet = async (payload: InputProps) => {
  const body = {
    query: `mutation {\n  statistic_locameet(input: { page_size: ${
      payload.limit
    }, page_index: ${payload.page - 1}, ${
      payload.searchMeet ? 'searchValue: "' + payload.searchMeet + '",' : ''
    }
     ${
       payload.searchReciever
         ? 'receiver_identity: "' + payload.searchReciever + '",'
         : ''
     }
   ${
     payload.dateFrom
       ? 'date_start: "' + dayjs(payload.dateFrom).format('YYYY-MM-DD') + '",'
       : ''
   }  ${
      payload.dateTo
        ? 'date_end: "' + dayjs(payload.dateTo).format('YYYY-MM-DD') + '",'
        : ''
    }
  }) {\n    data {\n      transaction_id\n      receiver_time\n      receiver_name\n       phone\n      email\n      action\n      type\n      amount\n      record_id\n      location\n      lat_long\n    }\n    numsOfRecord\n    lastWeekAvgReleaseToken\n    currentWeekAvgReleaseToken\n    currentHourReleaseToken\n lastestMd5\n   lastestReleaseTokenTime\n lastestMd5Value\n  lastestBscLink\n  todayTotalAction\n    lastHourTotalAction\n  totalDaoAmount\n }\n}\n`,
  };
  return axios.post(url, body);
};

const getListReleaseHistory = async (payload: InputProps) => {
  const body = {
    query: `mutation {\n  get_release_token_history(input: { page_size: ${
      payload.limit
    }, page_index: ${payload.page - 1}, ${
      payload.searchMeet ? 'searchValue: "' + payload.searchMeet + '",' : ''
    } ${
      payload.dateFrom
        ? 'date_start: "' + dayjs(payload.dateFrom).format('YYYY-MM-DD') + '",'
        : ''
    }  ${
      payload.dateTo
        ? 'date_end: "' + dayjs(payload.dateTo).format('YYYY-MM-DD') + '",'
        : ''
    }
  }) {\n    data {\n      id\n      release_at\n      token\n      nums_of_actions\n      md5\n     md5_value\n     bsc\n    }\n    totalReleaseToken\n    numsOfRecord\n    releaseTokenToday\n  lastestMd5\n   lastestReleaseTokenTime\n  lastestBscLink\n   avgReleaseTokenEveryDay\n  }\n}`,
  };
  return axios.post(url, body);
};

export { getListLocameet, getListReleaseHistory };
