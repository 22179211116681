import React from 'react';
import style from './InputSearch.module.scss';

export interface InputSearchProps {
  placeholder: string;
  handleChangeInput: React.ChangeEventHandler<HTMLInputElement>;
  id?: string;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
  value?: string;
}

function InputSearch(props: InputSearchProps) {
  const { placeholder, handleChangeInput, id, handleKeyDown, value } = props;
  return (
    <div className={style.Search}>
      <input
        id={id}
        className={style.InputSearch}
        type='text'
        placeholder={placeholder}
        onChange={handleChangeInput}
        autoComplete='off'
        onKeyDown={handleKeyDown}
        value={value}
      />
      <div className={style.IconSearch}>
        <img src='icon_search.svg' width={20} height={20} alt='logo' />
      </div>
    </div>
  );
}

export default InputSearch;
