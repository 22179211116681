import React, { useState, useEffect, useRef } from 'react';
import CustomTable from '../../components/CustomTable';
import { timeDateFormat, formatHide, numberWithDots } from '../../utils/common';
import ListBox from '../ListBox/ListBoxStatisticLocameet';
import Input from '../../components/Input';
import InputDateFromTo from '../../components/InputDateFromTo';
import style from './locameet.module.scss';
import { getListLocameet } from '../../api';
import ModalMD5 from '../../components/ModalMD5';
import { toast } from 'react-toastify';

type ModalRefMD5 = React.ElementRef<typeof ModalMD5>;

const typeAction = (type: string) => {
  switch (type) {
    case 'LOCA_MEET':
      return 'Điểm LocaBonus Meet';
    case 'REF_USER':
      return 'Điểm LocaBonus giới thiệu Meet';
    case 'NFT_OWNER':
      return 'Điểm LocaBonus sở hữu NFT';
    case 'NFT_EXPAND':
      return 'Điểm LocaBonus phát triển NFT';
    case 'NFT_RECOMMEND':
      return 'Điểm LocaBonus giới thiệu NFT';
    case 'ENTERPRISE_EXPAND':
      return 'Điểm LocaBonus phát triển DN';
    case 'ENTERPRISE_RECOMMEND':
      return 'Điểm LocaBonus giới thiệu DN';
    case 'NFT_COMPANY':
      return 'Điểm LocaBonus quản lý người phát triển NFT';
    case 'ENTERPRISE_COMPANY':
      return 'Điểm LocaBonus quản lý người phát triển DN';
    case 'DAO':
      return 'Điểm LocaBonus quỹ DAO';
    default:
      return;
  }
};

const cols = [
  {
    title: 'Mã giao dịch',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
  },
  {
    title: 'Thời gian nhận',
    dataIndex: 'receiver_time',
    key: 'receiver_time',
    render: (index: number, record: any) => (
      <>{timeDateFormat(record?.receiver_time)}</>
    ),
  },
  {
    title: 'Người nhận',
    dataIndex: 'receiver_name',
    key: 'receiver_name',
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    key: 'phone',
    render: (index: number, record: any) => <>{formatHide(record?.phone, 3)}</>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (index: number, record: any) => <>{formatHide(record?.email, 3)}</>,
  },
  {
    title: 'Hoạt động',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'Loại Điểm LocaBonus phát sinh',
    dataIndex: 'type',
    key: 'type',
    render: (index: number, record: any) => <>{typeAction(record?.type)}</>,
  },
  {
    title: 'Biến động',
    dataIndex: 'amount',
    key: 'amount',
    render: (index: number, record: any) => (
      <>{numberWithDots(record?.amount)}</>
    ),
  },
  {
    title: 'ID Meeting',
    dataIndex: 'record_id',
    key: 'record_id',
  },
  {
    title: 'Địa điểm Meet',
    dataIndex: 'location',
    key: 'location',
  },
  // {
  //   title: 'Lat Long',
  //   dataIndex: 'lat_long',
  //   key: 'lat_long',
  // },
];

function StatisticLocameet() {
  const refModal = useRef<ModalRefMD5>(null);
  const [textMD5, setTextMD5] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 6,
    searchMeet: window.location.pathname?.toString()?.slice(1) || '',
    searchReciever: '',
  });
  const [data, setData] = useState<any>();
  const [date, setDate] = useState({
    dateTo: '',
    dateFrom: '',
    errorDateTo: '',
    errorDateFrom: '',
  });
  const { dateTo, dateFrom, errorDateTo, errorDateFrom } = date;
  const { page, limit, searchMeet, searchReciever } = pagination;

  const getData = async () => {
    setLoading(true);
    const payload: any = {
      limit,
      page,
      searchMeet,
      searchReciever,
      dateFrom,
      dateTo,
    };
    try {
      const res: any = await getListLocameet(payload);
      setTimeout(() => {
        if (
          res?.data &&
          res?.data?.data &&
          res.data?.data?.statistic_locameet
        ) {
          setData(res.data?.data?.statistic_locameet);
          setLoading(false);
        } else {
          if (res.data?.errors?.length) {
            if (
              res.data?.errors[0]?.message ===
              'YOU ONLY HAVE 10 TIMES TO SEARCH BY EMAIL/PHONE'
            )
              toast.warning(
                'Bạn chỉ có tối đa 10 lần search theo số điện thoại hoặc email trong 1 ngày',
                { theme: 'colored' }
              );
          }
          setData([]);
          setLoading(false);
        }
      }, 500);
    } catch (err) {
      console.log(err);
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = (page: number) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchMeet = e.target.value;
    setPagination((prev) => ({ ...prev, searchMeet }));
  };

  const handleChangeInputPhoneOrEmail = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchReciever = e.target.value;
    setPagination((prev) => ({ ...prev, searchReciever }));
  };

  const handleSearch = () => {
    if (!errorDateTo && !errorDateFrom) {
      getData();
      setPagination((prev) => ({ ...prev, page: 1 }));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleShowInfoMD5 = (value: string) => {
    refModal.current?.toggleModal();
    setTextMD5(value);
  };

  return (
    <>
      <ListBox data={data} />
      <div style={{ marginTop: 30 }}>
        <div className={style.infoMeet}>
          Lần phát hành gần nhất:{' '}
          <strong>{timeDateFormat(data?.lastestReleaseTokenTime)}</strong>
        </div>
        <div className={style.infoMeet}>
          Link BSCScan:{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            target='_blank'
            href='https://bscscan.com/address/0xc9cca607a8c785b19ac7658d7f10b20b568a9d0e'
            style={{ fontWeight: 'bold' }}>
            https://bscscan.com/address/0xc9cca607a8c785b19ac7658d7f10b20b568a9d0e
          </a>
        </div>
        <div className={style.infoMeet}>
          Link OpenSea:{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            target='_blank'
            href='https://opensea.io/collection/locabonus-daily-report?search[sortAscending]=false&search[sortBy]=CREATED_DATE'
            style={{ fontWeight: 'bold' }}>
            https://opensea.io/collection/locabonus-daily-report?search[sortAscending]=false&search[sortBy]=CREATED_DATE
          </a>
        </div>
        <div className={style.infoMeet}>
          MD5 gần nhất:{' '}
          <strong onClick={() => handleShowInfoMD5(data?.lastestMd5Value)}>
            {data?.lastestMd5}
          </strong>
        </div>
      </div>
      <div className={style.changeInput}>
        <div className={style.inputMeet}>
          <Input
            placeholder='Tìm kiếm theo transaction id/ id meetting'
            handleChangeInput={handleChangeInput}
            handleKeyDown={handleKeyDown}
            value={searchMeet}
          />
        </div>
        <div className={style.inputMeet}>
          <Input
            placeholder='Tìm kiếm theo sđt/ email'
            handleChangeInput={handleChangeInputPhoneOrEmail}
            handleKeyDown={handleKeyDown}
          />
        </div>

        <div className={style.inputDate}>
          <InputDateFromTo
            date={date}
            setDate={setDate}
            handleFilterDateTran={handleSearch}
          />
        </div>
      </div>
      <CustomTable
        data={data?.data}
        columns={cols}
        currentPage={page}
        handlePagination={handlePagination}
        totalPage={Math.ceil(data?.numsOfRecord / limit)}
        pageSize={limit}
        loading={loading}
      />
      <ModalMD5 ref={refModal} text={textMD5} />
    </>
  );
}

export default StatisticLocameet;
