import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';
import style from './modal.module.scss';

interface IRef {
  toggleModal: () => void;
}

interface IProps {
  text: string;
}

const ModalRef: React.ForwardRefRenderFunction<IRef, IProps> = (
  props,
  ref
): JSX.Element => {
  const { text } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useImperativeHandle(ref, () => {
    return {
      toggleModal: () => setIsOpen(!isOpen),
      isOpen,
    };
  });

  const handleCancelPopup = () => {
    setIsOpen(!isOpen);
  };

  function copyTextToClipboard(text: string) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(text);
    } else {
      let textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        // textArea.remove();
      });
    }
  }

  // onClick handler function for the copy button
  const copyInput = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        toast.success('Sao chép thành công', { theme: 'colored' });
        // If successful, update the isCopied state value
      })
      .catch((err) => {
        console.log(err);
        toast.error('Sao chép thất bại', { theme: 'colored' });
      });
  };

  return (
    <>
      {isOpen && (
        <div className={style.modalWrapper}>
          <div className={style.modalContent}>
            <div className={style.closeOTP} onClick={handleCancelPopup}>
              <img
                src='icon_close.svg'
                width={16}
                height={16}
                alt='icon-close'
              />
            </div>
            <div className={style.descMD5}>
              Mã MD5 này là mã hoá của các thông số: Mã giao dịch phát hành điểm
              LocaBonus, Thời gian phát hành, Tổng điểm LocaBonus phát hành,
              Tổng số hoạt động trong khung giờ, Tổng số hoạt động khung giờ
              trước. Nếu hệ thống bị thay đổi thì mã MD5 này sẽ thay đổi. Bạn có
              thể kiểm tra bằng cách chạy mã hoá MD5 các thông số này trên
              website:{' '}
              <a
                href='https://www.md5hashgenerator.com'
                target='_blank'
                rel='noreferrer'>
                https://www.md5hashgenerator.com
              </a>
            </div>
            <div className='grp_btn'>
              <input
                id='code'
                type='code'
                className='input'
                disabled
                value={text}
              />
              <button
                id='btn_share'
                onClick={copyInput}
                className='btn btn_confirm'>
                Sao chép
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(ModalRef);
