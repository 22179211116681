import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';

interface DatePickerProps {
  onChange?: any;
  value?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  maxDate?: Date;
  isMonth?: boolean;
  isRequired?: boolean;
}

const RCDatePicker = (props: DatePickerProps) => {
  const {
    onChange,
    value,
    label,
    placeholder,
    disabled,
    maxDate,
    isMonth,
    isRequired,
  } = props;
  const date = value ? new Date(value) : null;
  return (
    <div className={styles.datePicker}>
      <p>
        {label} {isRequired && <span className={styles.requiredInput}>*</span>}
      </p>
      <DatePicker
        onChange={onChange}
        selected={date}
        dateFormat={isMonth ? 'MM/yyyy' : 'dd/MM/yyyy'}
        className={styles.inputDate}
        placeholderText={placeholder}
        disabled={disabled}
        scrollableYearDropdown
        showMonthYearPicker={isMonth}
        showYearDropdown
        yearDropdownItemNumber={100}
        maxDate={maxDate}
      />
      <div className={styles.icon}>
        <img src='icon-date-black.svg' width={18} height={20} alt='' />
      </div>
    </div>
  );
};
export default RCDatePicker;
