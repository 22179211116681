import React, { useState, useEffect, useRef } from 'react';
import CustomTable from '../../components/CustomTable';
import { numberWithDots, timeDateFormat } from '../../utils/common';
import ListBox from '../ListBox/ListBoxReleaseHistory';
import Input from '../../components/Input';
import InputDateFromTo from '../../components/InputDateFromTo';
import style from './history.module.scss';
import { getListReleaseHistory } from '../../api';
import ModalMD5 from '../../components/ModalMD5';

type ModalRefMD5 = React.ElementRef<typeof ModalMD5>;

const cols = [
  { title: 'Id', dataIndex: 'id', key: 'id' },
  {
    title: 'Thời gian phát hành',
    dataIndex: 'release_at',
    key: 'release_at',
    render: (index: number, record: any) => (
      <>{timeDateFormat(record?.release_at)}</>
    ),
  },
  {
    title: 'Tổng LocaBonus phát hành',
    dataIndex: 'token',
    key: 'token',
    render: (index: number, record: any) => (
      <>{numberWithDots(record?.token)}</>
    ),
  },
  {
    title: 'Tổng số hoạt động',
    dataIndex: 'nums_of_actions',
    key: 'nums_of_actions',
  },
  {
    title: 'MD5',
    dataIndex: 'md5',
    key: 'md5',
  },
  {
    title: 'OpenSea',
    dataIndex: 'bsc',
    key: 'bsc',
    render: (_: number, record: any) => (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a target='_blank' href={record?.bsc}>
        {record?.bsc ? (
          <img
            src='Logomark-White.svg'
            alt='locameet'
            className={style.logoOpenSea}
          />
        ) : null}
      </a>
    ),
  },
];

function StatisticLocameet() {
  const refModal = useRef<ModalRefMD5>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 6,
    searchMeet: '',
  });
  const [data, setData] = useState<any>();
  const [date, setDate] = useState({
    dateTo: '',
    dateFrom: '',
    errorDateTo: '',
    errorDateFrom: '',
  });
  const [textMD5, setTextMD5] = useState<string>('');
  const { dateTo, dateFrom, errorDateTo, errorDateFrom } = date;

  const { page, limit, searchMeet } = pagination;

  const getData = async () => {
    setLoading(true);
    const body: any = { page, limit, searchMeet, dateFrom, dateTo };
    try {
      const res: any = await getListReleaseHistory(body);
      setTimeout(() => {
        if (
          res?.data &&
          res?.data?.data &&
          res.data?.data?.get_release_token_history
        ) {
          setData(res.data?.data?.get_release_token_history);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      }, 500);
    } catch (err) {
      console.log(err);
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePagination = (page: number) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchMeet = e.target.value;
    setPagination((prev) => ({ ...prev, searchMeet, page: 1 }));
  };

  const handleSearch = () => {
    if (!errorDateTo && !errorDateFrom) {
      getData();
      setPagination((prev) => ({ ...prev, page: 1 }));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleShowInfoMD5 = (value: string) => {
    refModal.current?.toggleModal();
    setTextMD5(value);
  };
  return (
    <>
      <ListBox data={data} />
      <div style={{ marginTop: 30 }}>
        <div className={style.infoMeet}>
          Lần phát hành gần nhất:{' '}
          <strong>{timeDateFormat(data?.lastestReleaseTokenTime)}</strong>
        </div>
        <div className={style.infoMeet}>
          Link BSCScan:{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            target='_blank'
            href='https://bscscan.com/address/0xc9cca607a8c785b19ac7658d7f10b20b568a9d0e'
            style={{ fontWeight: 'bold' }}>
            https://bscscan.com/address/0xc9cca607a8c785b19ac7658d7f10b20b568a9d0e
          </a>
        </div>
        <div className={style.infoMeet}>
          Link OpenSea:{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            target='_blank'
            href='https://opensea.io/collection/locabonus-daily-report?search[sortAscending]=false&search[sortBy]=CREATED_DATE'
            style={{ fontWeight: 'bold' }}>
            https://opensea.io/collection/locabonus-daily-report?search[sortAscending]=false&search[sortBy]=CREATED_DATE
          </a>
        </div>
        <div className={style.infoMeet}>
          MD5 gần nhất:{' '}
          <strong onClick={() => handleShowInfoMD5(data?.lastestMd5Value)}>
            {data?.lastestMd5}
          </strong>
        </div>
      </div>

      <div className={style.changeInput}>
        <div className={style.inputMeet}>
          <Input
            placeholder='Tìm kiếm theo id lịch sử phát hành'
            handleChangeInput={handleChangeInput}
            handleKeyDown={handleKeyDown}
          />
        </div>
        <div className={style.inputDate}>
          <InputDateFromTo
            date={date}
            setDate={setDate}
            handleFilterDateTran={handleSearch}
          />
        </div>
      </div>
      <CustomTable
        data={data?.data}
        columns={cols}
        currentPage={page}
        handlePagination={handlePagination}
        totalPage={Math.ceil(data?.numsOfRecord / limit)}
        pageSize={limit}
        handleShowInfoMD5={handleShowInfoMD5}
        loading={loading}
      />
      <ModalMD5 ref={refModal} text={textMD5} />
    </>
  );
}

export default StatisticLocameet;
