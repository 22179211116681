import React from 'react';
import Box from '../../components/Box';
import style from './listBox.module.scss';

function ListBoxReleaseHistory({ data }: any) {
  return (
    <div className={style.rowBox}>
      <Box
        name='Tổng điểm LocaBonus đã phát hành'
        total={data?.totalReleaseToken}
      />
      <Box
        name='Lượng Điểm LocaBonus phát hành trong ngày'
        total={data?.releaseTokenToday}
      />
      <Box
        name='Lượng Điểm LocaBonus trung bình theo ngày'
        total={data?.avgReleaseTokenEveryDay}
      />
    </div>
  );
}

export default ListBoxReleaseHistory;
