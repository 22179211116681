import dayjs from 'dayjs';
export function convertPhoneVietnameseDotFirst(value: string) {
  if (!value) return '';
  if (value.slice(0, 2).toString() === '84') return '0' + value.slice(2);
  else return value;
}

export const timeDateFormat = (date: string): string => {
  if (!date) return '';
  const minute = dayjs(date).minute();
  const hour = dayjs(date).hour();
  const year = dayjs(date).format('DD/MM/YYYY');
  return `${hour.toString().length > 1 ? hour : '0' + hour}:${
    minute.toString().length > 1 ? minute : '0' + minute
  } - ${year}`;
};

export const formatHide = (str: string, showCount: number) => {
  if (str?.length > showCount * 2) {
    return str.slice(0, showCount) + '****' + str.slice(str.length - showCount);
  }
  return str;
};

export function numberWithDots(x: number) {
  if (!x) return 0;
  const arrStr = parseFloat(x.toString()).toFixed(2).split('.');
  let res = arrStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return arrStr.length === 1 ? res : `${res},${arrStr[1].slice(0, 2)}`;
}
