import React from 'react';
import style from './button.module.scss';
interface ButtonProps {
  type?: any;
  text?: string;
  handleSubmit?: React.MouseEventHandler<HTMLElement>;
  isDisabled?: boolean;
  isCancel?: boolean;
  id?: string;
}

function Button(props: ButtonProps) {
  const { text, handleSubmit, isDisabled, isCancel, type, id } = props;
  return (
    <button
      onClick={handleSubmit}
      className={isCancel ? style.cancel : style.submit}
      disabled={isDisabled}
      style={{ opacity: isDisabled ? 0.5 : 1 }}
      type={type}
      id={id}>
      <div>{text}</div>
    </button>
  );
}

export default Button;
